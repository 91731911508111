const fitPlanUrl = process.env.GATSBY_FIT_PLAN_URL;
module.exports = {
  apiBaseUrl: `${fitPlanUrl}`,
  imageBaseUrl: process.env.GATSBY_IMAGE_BASE_URL,
  stripeProductId: process.env.GATSBY_STRIPE_PRODUCT_ID,
  stripePublicKey: process.env.GATSBY_STRIPE_PUBLIC_KEY,
  googleApiClientId: process.env.GATSBY_GOOGLE_API_CLIENT_ID,
  facebookAppId: process.env.GATSBY_FACEBOOK_APP_ID,
  fitplanApiLoginClientId: process.env.GATSBY_FITPLAN_API_LOGIN_CLIENT_ID,
  fitplanApiLoginClientSecret:
    process.env.GATSBY_FITPLAN_API_LOGIN_CLIENT_SECRET,
  fitplanClientId: process.env.FITPLAN_API_CLIENT_ID,
  fitplanClientSecret: process.env.FITPLAN_API_CLIENT_SECRET,
  appleSignInClientId: process.env.GATSBY_APPLE_SIGNIN_CLIENT_ID,
  appleSignInRedirectUri: process.env.GATSBY_APPLE_SIGNIN_REDIRECT_URI,
  urls: {
    root: `${fitPlanUrl}/`,
    base: `${fitPlanUrl}/web/`,
    token: `${fitPlanUrl}/oauth/token`,
    userLogin: `${fitPlanUrl}/website/user/login`,
    userSignup: `${fitPlanUrl}/website/user/signup`,
    getStripePlans: `${fitPlanUrl}/web/stripe/payment/plans`,
    submitPayment: `${fitPlanUrl}/web/stripe/submit/payment`,
    submitOneTimePayment: `${fitPlanUrl}/web/stripe/submit/onetime/payment`,
    getUserProfile: `${fitPlanUrl}/v2/user/profile`,
    profileChange: `${fitPlanUrl}/v2/user/profile/change`,
    googleLogin: `${fitPlanUrl}/v2/user/signup/google`,
    facebookLogin: `${fitPlanUrl}/v2/user/signup/facebook/2`,
    changePassword: `${fitPlanUrl}/v2/web/password/change`,
    forgotPassword: `${fitPlanUrl}/v2/web/password/forgot`,
    resetPassword: `${fitPlanUrl}/v2/web/password/reset`,
    contact: `${fitPlanUrl}/v2/email/contact`,
  },
  api: {
    base: process.env.GATSBY_API_BASE_URI,
    login: "login",
    user: "user",
    updateUser: "user/update",
    postMessage: "contact-us",
    signup: "signup",
    coupon: "coupon",
    updatePaymentMethod: "update-stripe",
    stripeToken: "stripe-token",
    cancelStripe: "cancel-stripe",
    resetPassword: "reset-password",
    facebook: "facebook",
    forgotPassword: "forgot-password",
    changePassword: "change-password",
  },
  stripe: {
    base: process.env.GATSBY_STRIPE_API_BASE_URI,
    plans: "get-stripe-plans",
  },
};
