import React from "react";
import { AuthProvider } from "@fitplan/context/lib-es/auth";
import { ConfigContext } from "@fitplan/context/lib-es/config";
import * as config from "./src/config";

import "./src/global/index.scss";
import "@fortawesome/fontawesome/styles.css";

const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://player.vimeo.com/api/player.js");
  };
};

export const wrapRootElement = ({ element }) => (
  <ConfigContext.Provider value={config}>
    <AuthProvider apiBaseUrl={config.apiBaseUrl}>{element}</AuthProvider>
  </ConfigContext.Provider>
);
